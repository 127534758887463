import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { DEFAULT_LANG } from 'main/constants/langs';
import { getlangFromPath } from 'main/helpers/langs';
import buildRouteWithLang from 'main/helpers/buildRouteWithLang';

const RouterLinkWithLang = React.forwardRef((props, ref) => {
  const { children, to: route } = props;
  const {
    location: { pathname },
  } = useHistory();

  const currentLang = getlangFromPath(pathname) || DEFAULT_LANG;

  return (
    <Link {...props} ref={ref} to={buildRouteWithLang(currentLang, route)}>
      {children}
    </Link>
  );
});

export default RouterLinkWithLang;
