import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import Error from 'main/components/_Error';
import PageWrapper from 'main/components/PageWrapper';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('error.404')}</title>
      </Helmet>
      <PageWrapper alignCenter>
        <Error />
      </PageWrapper>
    </>
  );
};

export default NotFound;
