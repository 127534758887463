import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import useHistoryWithLang from 'main/utils/useHistoryWithLang';

const useStyles = makeStyles((theme) => ({
  error: {
    display: 'flex',
    justifyContent: 'center',
  },
  error__notFound: {
    textAlign: 'center',
  },
  errorNumber: {
    fontSize: '10rem',
    [theme.breakpoints.up('xl')]: {
      fontSize: '14rem',
    },
  },
  errorButton: {
    marginTop: '1rem',
  },
  footballIcon: {
    width: '7.2rem',
    margin: '0 0.5rem',
    [theme.breakpoints.up('xl')]: {
      width: '10rem',
    },
  },
}));

function Error() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { push: pushRoute } = useHistoryWithLang();

  const renderNotFoundError = () => (
    <div className={classes.error__notFound}>
      <Typography
        color="secondary"
        variant="h4"
        display="inline"
        className={classes.errorNumber}
      >
        4
      </Typography>
      <img src="/icons/football-ball.svg" className={classes.footballIcon} />
      <Typography
        color="secondary"
        display="inline"
        variant="h4"
        className={classes.errorNumber}
      >
        4
      </Typography>
      <Typography variant="h5">{t('error.404')}</Typography>
      <Button
        color="primary"
        className={classes.errorButton}
        disableRipple
        onClick={() => pushRoute('/')}
      >
        {t('toHome')}
      </Button>
    </div>
  );

  return (
    <div className={classes.error}>
      <Route
        render={({ staticContext }) => {
          if (staticContext) staticContext.statusCode = 404;
          return renderNotFoundError();
        }}
      />
    </div>
  );
}

export default Error;
