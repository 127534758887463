import Home from 'main/pages/Home';
import News from 'client/main/pages/News';
import Contacts from 'client/main/pages/Contacts';
import NotFound from 'main/pages/NotFound';

const routes = [
  {
    path: '/',
    exact: true,
    component: Home,
  },
  {
    path: '/news',
    exact: true,
    component: News,
  },
  {
    path: '/contacts',
    exact: true,
    component: Contacts,
  },
];

const errorRoute = {
  component: NotFound,
};

const routesWithLangParam = routes.map(({ path, ...rest }) => ({
  path: `/:lang([a-z]{2})${path}`,
  ...rest,
}));

export default [...routes, ...routesWithLangParam, errorRoute];
