import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme();
theme.palette = {
  ...theme.pallete,
  primary: {
    light: '#ADAFDD',
    main: '#1a18f7',
    dark: '#1919db',
    contrastText: '#fff',
  },
  secondary: {
    light: '#f7e3d6',
    main: '#f22613',
    dark: '#d91e18',
    contrastText: '#fff',
  },
};

export default createMuiTheme({
  ...theme,
  mixins: {
    toolbar: {
      minHeight: 56,
      [theme.breakpoints.up('sm')]: {
        minHeight: 64,
      },
      [theme.breakpoints.up('lg')]: {
        minHeight: 80,
      },
      [theme.breakpoints.up('xl')]: {
        minHeight: 100,
      },
    },
  },
  overrides: {
    MuiDivider: {
      root: {
        height: 2,
        backgroundColor: '#000',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 0,
      },
      outlined: {
        border: '2px solid #000',
        '&$disabled': {
          border: '2px solid rgba(0, 0, 0, 0.12)',
        },
      },
      outlinedPrimary: {
        border: `2px solid ${theme.palette.primary.main}`,
        '&:hover': {
          border: `2px solid ${theme.palette.primary.main}`,
        },
      },
      outlinedSizeLarge: {
        [theme.breakpoints.up('xl')]: {
          padding: '10px 30px',
        },
      },
    },
    MuiTextField: {
      root: {
        '&:not(:first-of-type)': {
          marginTop: 32,
          [theme.breakpoints.up('xl')]: {
            marginTop: 48,
          },
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: '#000',
      },
    },
    MuiInputBase: {
      root: {
        color: '#000',
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: '2px solid #000',
        },
      },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: theme.palette.secondary.light,
      },
    },
  },
  typography: {
    h1: {
      fontSize: 36,
      fontFamily: 'Roboto Condensed, sans-serif',
      letterSpacing: 3,
      [theme.breakpoints.up('sm')]: {
        fontSize: 42,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 48,
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: 64,
      },
    },
    h3: {
      fontSize: 28,
      fontWeight: 500,
      [theme.breakpoints.up('lg')]: {
        fontSize: 30,
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: 36,
      },
    },
    h4: {
      fontSize: 24,
      fontWeight: 500,
      [theme.breakpoints.up('xl')]: {
        fontSize: 32,
      },
    },
    body1: {
      fontWeight: 400,
      [theme.breakpoints.up('xl')]: {
        fontSize: 20,
      },
    },
    body2: {
      [theme.breakpoints.up('xl')]: {
        fontSize: 16,
      },
    },
  },
});
