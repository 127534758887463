import { db } from 'utils/initFirebase';

export const getDocumentSnapshotById = (collectionName, docId) =>
  db().collection(collectionName).doc(docId).get();

export const getCollectionLength = (collectionName) =>
  db()
    .collection(collectionName)
    .get()
    .then((documentSnapshots) => documentSnapshots.docs.length);

export const unwrapDoc = (doc) => ({
  ...doc.data(),
  id: doc.id,
});

export const unwrapCollection = (collection) => {
  const arr = [];
  collection.forEach((doc) => arr.push(unwrapDoc(doc)));
  return arr;
};
