import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import PageHeader from 'main/components/PageHeader';
import PageWrapper from 'main/components/PageWrapper';
import ContactsContainer from 'main/components/ContactsContainer';

const ContactsPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('meta.contacts.title')}</title>
        <meta name="description" content={t('meta.contacts.description')} />
      </Helmet>
      <PageWrapper>
        <>
          <PageHeader title={t('contacts')} />
          <ContactsContainer />
        </>
      </PageWrapper>
    </>
  );
};

export default ContactsPage;
