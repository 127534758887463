import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  footer: {
    marginTop: 'auto',
    padding: `${theme.spacing(2)}px 0`,
    [theme.breakpoints.up('sm')]: {
      padding: `${theme.spacing(3)}px 0`,
    },
  },
  footer__bottom: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
    },
  },
  footer__copyright: {
    [theme.breakpoints.only('xs')]: {
      marginBottom: theme.spacing(1),
    },
  },
}));

export default function Footer() {
  const classes = useStyles();
  const currentYear = useMemo(() => new Date().getFullYear(), []);
  const { t } = useTranslation();

  return (
    <footer className={classes.footer}>
      <Container maxWidth={false}>
        <div className={classes.footer__bottom}>
          <div className={classes.footer__copyright}>
            <Typography variant="body2">
              {t('copyright', { year: currentYear })}
            </Typography>
          </div>
          <div>
            <Typography variant="body2" noWrap>
              {t('designedBy')}{' '}
              <Link href="mailto:iamafanasev@gmail.com">OLAF</Link>
            </Typography>
          </div>
        </div>
      </Container>
    </footer>
  );
}
