import { useState } from 'react';
import i18next from 'i18next';
import { useHistory } from 'react-router-dom';

import { DEFAULT_LANG } from 'main/constants/langs';
import { isDefaultLang, getlangFromPath } from 'main/helpers/langs';

const useLang = () => {
  const history = useHistory();
  const {
    location: { pathname },
  } = history;

  const lang = getlangFromPath(pathname) || DEFAULT_LANG;
  const [currentLang = lang, setCurrentLang] = useState();

  const changeLanguage = (nextLang) => {
    i18next.changeLanguage(nextLang, (err) => {
      if (err) throw err;

      const pathParts = pathname.split('/').filter((part) => part);
      const pathPartsWithoutLangPart = isDefaultLang(currentLang)
        ? pathParts
        : pathParts.slice(1);

      const langPath = isDefaultLang(nextLang) ? '/' : `/${nextLang}/`;
      const nextPathName = [langPath, pathPartsWithoutLangPart.join('/')].join(
        ''
      );

      history.replace(nextPathName);
      setCurrentLang(nextLang);
    });
  };

  return { currentLang, changeLanguage };
};

export default useLang;
