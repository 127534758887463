import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

import { RU } from 'main/constants/langs';

const options = {
  fallbackLng: RU,
  load: 'languageOnly',
  detection: {
    order: ['path'],
  },

  ns: ['translations'],
  defaultNS: 'translations',

  saveMissing: true,
  missingKeyHandlerL: true,

  interpolation: {
    escapeValue: false,
  },

  wait: process && !process.release,
};

if (process && !process.release) {
  i18n.use(Backend).use(initReactI18next);
}

if (!i18n.isInitialized) i18n.init(options);

export default i18n;
