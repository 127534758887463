import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import Achievements from 'main/components/Achievements';
import PageWrapper from 'main/components/PageWrapper';
import Promo from 'main/components/Promo';
import Results from 'main/components/Results';

const HomePage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('meta.home.title')}</title>
        <meta name="description" content={t('meta.home.description')} />
      </Helmet>
      <PageWrapper>
        <div>
          <Promo />
          <Achievements />
          <Results />
        </div>
      </PageWrapper>
    </>
  );
};

HomePage.getInitialProps = async () => {
  return {};
};

export default HomePage;
