import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import useLang from 'client/main/utils/useLang';
import { toolbarHeigthByBreakPoint } from 'client/main/constants/styleVariables';

const useStyles = makeStyles((theme) => ({
  promo: {
    position: 'relative',
    width: '100vw',
    height: 'calc(100vh - 96px)',
    minHeight: 320,
    display: 'flex',
    overflow: 'initial',
    boxSizing: 'border-box',
    paddingTop: 50,
    marginLeft: -16,
    marginTop: -toolbarHeigthByBreakPoint.xs,
    [theme.breakpoints.up('sm')]: {
      height: '100vh',
      marginLeft: -24,
      marginTop: -toolbarHeigthByBreakPoint.sm,
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: -toolbarHeigthByBreakPoint.lg,
    },
    [theme.breakpoints.up('xl')]: {
      marginTop: -toolbarHeigthByBreakPoint.xl,
    },
  },
  promo__containerCover: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: -1,
    width: '100%',
    height: '100%',
    '&:after': {
      content: "''",
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
    },
  },
  promo__cover: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  promo__content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 'auto 0',
    flexGrow: 1,
    textAlign: 'center',
    color: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
      transform: 'translateY(-24px)',
    },
  },
  promo__title: {
    fontWeight: 700,
    fontSize: 46,
    letterSpacing: 8,
    [theme.breakpoints.up('sm')]: {
      fontSize: 64,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 100,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 140,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 160,
    },
  },
  promo__subTitle: {
    fontWeight: 400,
    marginTop: 8,
    [theme.breakpoints.up('md')]: {
      marginTop: -8,
    },
  },
  promo__description: {
    marginTop: theme.spacing(2),
    maxWidth: 280,
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(5),
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: 340,
    },
  },
  promo__circleActivity: {
    position: 'absolute',
    top: '100%',
    left: '50%',
    width: '18vmax',
    marginTop: '-9vmax',
    marginLeft: '-9vmax',
    [theme.breakpoints.up('sm')]: {
      width: '16vmax',
      marginTop: '-8vmax',
      marginLeft: '-8vmax',
    },
    [theme.breakpoints.up('md')]: {
      width: '10vmax',
      marginTop: '-5vmax',
      marginLeft: '-5vmax',
    },
    [theme.breakpoints.up('xl')]: {
      width: '10vmax',
      marginTop: '-5vmax',
      marginLeft: '-5vmax',
    },
  },
}));

const circleActivityIconPathByLang = {
  ru: '/icons/circle-activity.svg',
  en: '/icons/circle-activity-en.svg',
};

const Promo = () => {
  const [rotateDeg, setRotateDeg] = useState(0);
  const classes = useStyles();
  const { t } = useTranslation();
  const { currentLang } = useLang();

  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (prevPos.y <= currPos.y) {
        setRotateDeg(currPos.y / 3);
      } else {
        setRotateDeg(rotateDeg - (rotateDeg - currPos.y / 3));
      }
    },
    [rotateDeg]
  );

  return (
    <>
      <div className={classes.promo}>
        <picture className={classes.promo__containerCover}>
          <source
            className={classes.promo__cover}
            srcSet="/main-psft-pic_medium_35.jpg"
            media="(max-width: 1024px)"
          />
          <source
            className={classes.promo__cover}
            srcSet="/main-psft-pic_large_35.jpg"
            media="(min-width: 1024px)"
          />
          <img
            className={classes.promo__cover}
            src="/main-psft-pic_medium_35.jpg"
          />
        </picture>
        <div className={classes.promo__content}>
          <h1>
            <Typography
              variant="h1"
              component="span"
              display="block"
              className={classes.promo__title}
            >
              {t('promo.beginTitle').toUpperCase()}
            </Typography>
            <Typography
              variant="h4"
              component="span"
              display="block"
              className={classes.promo__subTitle}
            >
              {t('promo.endTitle').toUpperCase()}
            </Typography>
          </h1>
          <Typography color="inherit" className={classes.promo__description}>
            {t('promo.activity')}
          </Typography>
        </div>
        <img
          src={circleActivityIconPathByLang[currentLang]}
          className={classes.promo__circleActivity}
          style={{ transform: `rotate(${-rotateDeg}deg)` }}
        />
      </div>
    </>
  );
};

export default Promo;
