import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';

import { NEWS_ROUTE, CONTACTS_ROUTE } from 'main/constants/routes';
import { isHomeRoute } from 'main/helpers/routes';
import { toolbarHeigthByBreakPoint } from 'client/main/constants/styleVariables';

import HeaderAndSidebarContainer from './HeaderAndSidebarContainer';
import Footer from './Footer';

const navRoutes = [
  { id: 1, link: NEWS_ROUTE, keyToName: 'navRoutes.news' },
  { id: 2, link: CONTACTS_ROUTE, keyToName: 'navRoutes.contacts' },
];

const useStyles = makeStyles((theme) => ({
  layout: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: `calc(100vh - ${toolbarHeigthByBreakPoint.xs}px)`,
    paddingTop: toolbarHeigthByBreakPoint.xs,
    [theme.breakpoints.up('sm')]: {
      minHeight: `calc(100vh - ${toolbarHeigthByBreakPoint.sm}px)`,
      paddingTop: toolbarHeigthByBreakPoint.sm,
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: `calc(100vh - ${toolbarHeigthByBreakPoint.lg}px)`,
      paddingTop: toolbarHeigthByBreakPoint.lg,
    },
    [theme.breakpoints.up('xl')]: {
      minHeight: `calc(100vh - ${toolbarHeigthByBreakPoint.xl}px)`,
      paddingTop: toolbarHeigthByBreakPoint.xl,
    },
  },
}));

function Layout({ title, children }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    location: { pathname },
  } = useHistory();
  const defaultTitle = t('defaultTitle');

  return (
    <>
      <Helmet>
        <title>{title || defaultTitle}</title>
      </Helmet>
      <div className={classes.layout}>
        <HeaderAndSidebarContainer
          isInverseColor={isHomeRoute(pathname)}
          navRoutes={navRoutes}
        />
        {children}
        <Footer />
      </div>
    </>
  );
}

export default Layout;
