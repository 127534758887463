import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  achievements: {
    padding: '6rem 0',
    [theme.breakpoints.up('sm')]: {
      padding: '4rem 0 6rem 0',
    },
  },
  achievements__beginTitle: {
    color: theme.palette.common.white,
  },
  achievements__endTitle: {
    fontWeight: 700,
  },
  achievements__subTitle: {
    padding: '2rem 0 1rem 0',
    [theme.breakpoints.up('md')]: {
      padding: '3rem 0 8rem 0',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '3rem 0 10rem 0',
    },
    [theme.breakpoints.up('xl')]: {
      padding: '5rem 0 14rem 0',
    },
  },
  achievements__item: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '5rem',
    },
  },
  achievements__itemContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    maxWidth: 280,
    [theme.breakpoints.up('xl')]: {
      maxWidth: 350,
    },
  },
  achievements__itemIconWrapper: {
    display: 'flex',
    paddingBottom: '2rem',
  },
  achievements__itemIcon: {
    maxHeight: '2.5rem',
    [theme.breakpoints.up('xl')]: {
      maxHeight: '4rem',
    },
  },
}));

const Achievements = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <section className={classes.achievements}>
      <h2>
        <Typography
          variant="h1"
          display="block"
          component="span"
          className={classes.achievements__beginTitle}
        >
          {t('achievements.beginTitle').toUpperCase()}
        </Typography>
        <Typography
          variant="h1"
          display="block"
          component="span"
          className={classes.achievements__endTitle}
        >
          {t('achievements.endTitle').toUpperCase()}
        </Typography>
      </h2>
      <Grid container className={classes.achievements__subTitle}>
        <Grid item sm={false} lg={6} />
        <Grid item xs={12} sm={8} lg={3}>
          <Typography variant="body1">{t('achievements.subTitle')}</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          xl={3}
          className={classes.achievements__item}
        >
          <div className={classes.achievements__itemContent}>
            <div className={classes.achievements__itemIconWrapper}>
              <img
                src="/icons/fifteen.svg"
                className={classes.achievements__itemIcon}
              />
            </div>
            <Typography variant="body1">
              {t('achievements.experience')}
            </Typography>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          xl={3}
          className={classes.achievements__item}
        >
          <div className={classes.achievements__itemContent}>
            <div className={classes.achievements__itemIconWrapper}>
              <img
                src="/icons/600-and-more.svg"
                className={classes.achievements__itemIcon}
              />
            </div>
            <Typography variant="body1">
              {t('achievements.numberOfProcesses')}
            </Typography>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          xl={3}
          className={classes.achievements__item}
        >
          <div className={classes.achievements__itemContent}>
            <div className={classes.achievements__itemIconWrapper}>
              <img
                src="/icons/none.svg"
                className={classes.achievements__itemIcon}
              />
            </div>
            <Typography variant="body1">
              {t('achievements.independence')}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </section>
  );
};

export default Achievements;
