import React, { useState } from 'react';

import Header from './Header';
import Sidebar from './Sidebar';

const HeaderAndSidebarContainer = ({ isInverseColor, navRoutes }) => {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const toggleDrawer = (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setIsOpenDrawer(!isOpenDrawer);
  };

  return (
    <>
      <Header
        isInverseColor={isInverseColor}
        handleToggleDrawer={toggleDrawer}
        navRoutes={navRoutes}
      />
      <Sidebar
        isOpen={isOpenDrawer}
        handleToggleDrawer={toggleDrawer}
        navRoutes={navRoutes}
      />
    </>
  );
};

HeaderAndSidebarContainer.defaultProps = {
  isInverseColor: false,
  navRoutes: {},
};

export default HeaderAndSidebarContainer;
