import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  pageHeader: {
    padding: '2rem 0 2rem 0',
    [theme.breakpoints.up('xl')]: {
      padding: '3rem 0 3rem 0',
    },
  },
  pageHeader__title: {
    textTransform: 'uppercase',
    color: '#fff',
  },
}));

const PageHeader = ({ title }) => {
  const classes = useStyles();
  return (
    <div className={classes.pageHeader}>
      <Typography
        color="inherit"
        variant="h1"
        className={classes.pageHeader__title}
      >
        {title}
      </Typography>
    </div>
  );
};

export default PageHeader;
