import React from 'react';
import { useTranslation } from 'react-i18next';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, makeStyles } from '@material-ui/core/styles';

import RouterLinkWithLang from './RouterLinkWithLang';

const useStyles = makeStyles(() => ({
  list: {
    margin: 'auto 0',
    width: 280,
  },
}));

const Sidebar = ({ isOpen, navRoutes, handleToggleDrawer }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const { t } = useTranslation();

  const renderList = () => (
    <div
      role="navigation"
      className={classes.list}
      onClick={handleToggleDrawer}
      onKeyDown={handleToggleDrawer}
    >
      <List>
        {navRoutes.map(({ id, link, keyToName }) => (
          <ListItem key={id}>
            <Link
              key={id}
              component={RouterLinkWithLang}
              to={link}
              color="inherit"
              className={classes.appBar__navLink}
            >
              <Typography variant="h4" color="primary">
                {t(keyToName).toUpperCase()}
              </Typography>
            </Link>
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    isMobile && (
      <SwipeableDrawer
        anchor="left"
        open={isOpen}
        onClose={handleToggleDrawer}
        onOpen={handleToggleDrawer}
        elevation={0}
      >
        {renderList()}
      </SwipeableDrawer>
    )
  );
};

Sidebar.defaultProps = {
  isOpen: false,
  navRoutes: {},
};

export default Sidebar;
