import React from 'react';
import { useTranslation } from 'react-i18next';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  contactsInfo: {
    marginTop: 32,
  },
}));

const ContactsInfo = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.contactsInfo}>
      <Typography variant="h5" component="div" paragraph>
        <Link color="inherit" href="tel:+79258017474">
          +7 (925) 801-74-74
        </Link>
      </Typography>
      <Typography paragraph>
        <Link href="mailto:data@psft.ru">data@psft.ru</Link>
      </Typography>
      <Typography>{t('adress')}</Typography>
    </div>
  );
};

export default ContactsInfo;
