import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Carousel from 'nuka-carousel';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import useCurrentBreakpoint from 'client/hooks/useCurrentBreakpoint';
import useLang from 'main/utils/useLang';

const itemWidthByBreakpoint = {
  xs: 0.75,
  sm: 0.462,
  md: 0.32,
  lg: 0.323,
  xl: 0.245,
};

const results = [
  {
    id: 1,
    number: '01',
    iconByLang: {
      ru: '/icons/before-23-free.svg',
      en: '/icons/before-23-free-en.svg',
    },
    keyToText: 'results.before23',
  },
  {
    id: 2,
    number: '02',
    iconByLang: {
      ru: '/icons/before-30-free.svg',
      en: '/icons/before-30-free-en.svg',
    },
    keyToText: 'results.before30',
  },
  {
    id: 3,
    number: '03',
    iconByLang: {
      ru: '/icons/no-personal.svg',
      en: '/icons/no-personal-en.svg',
    },
    keyToText: 'results.noPersonalTraining',
  },
  {
    id: 4,
    number: '04',
    iconByLang: {
      ru: '/icons/x3-month-wages.svg',
      en: '/icons/x3-month-wages-en.svg',
    },
    keyToText: 'results.x3MonthWages',
  },
  {
    id: 5,
    number: '05',
    iconByLang: {
      ru: '/icons/libra.svg',
      en: '/icons/libra.svg',
    },
    keyToText: 'results.dispute',
  },
  {
    id: 6,
    number: '06',
    iconByLang: {
      ru: '/icons/rubles.svg',
      en: '/icons/dollor-in-circle.svg',
    },
    keyToText: 'results.paymentDeclaration',
  },
  {
    id: 7,
    number: '07',
    iconByLang: {
      ru: '/icons/rfs.svg',
      en: '/icons/rfs-en.svg',
    },
    keyToText: 'results.rfs',
  },
  {
    id: 8,
    number: '08',
    iconByLang: {
      ru: '/icons/insurance.svg',
      en: '/icons/insurance.svg',
    },
    keyToText: 'results.insurance',
  },
];

const useStyles = makeStyles((theme) => ({
  results: {
    width: '100vw',
    marginLeft: -24,
    borderTop: `2px solid ${theme.palette.common.black}`,
    borderBottom: `2px solid ${theme.palette.common.black}`,
    overflow: 'hidden',
    '& ul.slider-list': {
      cursor: 'default !important',
    },
    '& li': {
      borderRight: `2px solid ${theme.palette.common.black}`,
    },
    '& *': {
      outline: 'none',
    },
  },
  results__list: {
    padding: '0 24px',
  },
  results__item: {
    boxSizing: 'border-box',
    padding: '48px 24px 32px 24px',
  },
  results__itemNumber: {
    position: 'relative',
    [theme.breakpoints.only('xs')]: {
      marginBottom: 32,
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: -32,
      width: 64,
      height: 2,
      backgroundColor: theme.palette.common.black,
    },
  },
  results__itemContent: {
    paddingTop: '15vmax',
    maxWidth: 320,
  },
  results__itemImgWrapper: {
    display: 'flex',
    height: 76,
    width: 114,
    marginBottom: '5vmax',
    [theme.breakpoints.up('sm')]: {
      height: 84,
      width: 128,
    },
    [theme.breakpoints.up('xl')]: {
      height: 90,
      width: 140,
    },
  },
  results__itemImg: {
    maxHeight: '100%',
    maxWidth: '100%',
  },
}));

const Results = () => {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();
  const { currentLang } = useLang();
  const isNeedControlsShow = useMediaQuery(theme.breakpoints.down('sm'));
  const currentBreakpoint = useCurrentBreakpoint() || 'lg';
  const slideWidth = useMemo(() => itemWidthByBreakpoint[currentBreakpoint], [
    currentBreakpoint,
  ]);

  const settings = {
    transitionMode: 'scroll',
    wrapAround: false,
    disableEdgeSwiping: true,
    slidesToShow: 1,
    slidesToScroll: 'auto',
    slideWidth,
    withoutControls: isNeedControlsShow,
  };

  return (
    <section className={classes.results}>
      <Carousel
        defaultControlsConfig={{
          nextButtonText: t('button.next'),
          prevButtonText: t('button.prev'),
          pagingDotsStyle: {
            display: 'none',
          },
        }}
        {...settings}
      >
        {results.map(({ id, number, iconByLang, keyToText }) => (
          <div key={id} className={classes.results__item}>
            <Typography
              variant="h4"
              color="primary"
              className={classes.results__itemNumber}
              component="div"
            >
              {number}
            </Typography>
            <div className={classes.results__itemContent}>
              <div className={classes.results__itemImgWrapper}>
                <img
                  src={iconByLang[currentLang]}
                  className={classes.results__itemImg}
                />
              </div>
              <Typography>{t(keyToText)}</Typography>
            </div>
          </div>
        ))}
      </Carousel>
    </section>
  );
};

export default Results;
