import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import { TextField } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';

const getShema = (t) => {
  return Yup.object().shape({
    name: Yup.string()
      .min(2, t('formErrorMessages.minLength', { count: 2 }))
      .max(50, t('formErrorMessages.maxLength', { count: 50 }))
      .required(t('formErrorMessages.required')),
    email: Yup.string()
      .email(t('formErrorMessages.email'))
      .required(t('formErrorMessages.required')),
    details: Yup.string()
      .min(2, t('formErrorMessages.minLength', { count: 2 }))
      .max(300, t('formErrorMessages.maxLength', { count: 300 })),
  });
};

const useStyles = makeStyles(() => ({
  form: {
    marginTop: 24,
  },
  form__button: {
    marginTop: '4rem',
  },
}));

const ContactsForm = ({ handleSubmitForm }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Formik
        initialValues={{
          name: '',
          email: '',
          details: '',
        }}
        validationSchema={getShema(t)}
        onSubmit={handleSubmitForm}
      >
        {({ isSubmitting, isValid, dirty, values: { name, details } }) => (
          <Form className={classes.form}>
            <Field
              component={TextField}
              name="name"
              type="text"
              label={t('form.name')}
              fullWidth
              InputProps={{
                endAdornment: name.length !== 0 && (
                  <InputAdornment position="end">
                    <Typography
                      variant="body2"
                      color={name.length > 50 ? 'secondary' : 'primary'}
                    >{`${name.length} / 50`}</Typography>
                  </InputAdornment>
                ),
              }}
            />
            <Field
              component={TextField}
              name="email"
              type="email"
              label={t('form.email')}
              fullWidth
            />
            <Field
              component={TextField}
              name="details"
              type="text"
              label={t('form.details')}
              multiline
              rows={5}
              placeholder={t('form.detailsPlacholder')}
              fullWidth
              InputProps={{
                endAdornment: details.length !== 0 && (
                  <InputAdornment position="end">
                    <Typography
                      variant="body2"
                      color={details.length > 300 ? 'secondary' : 'primary'}
                    >{`${details.length} / 300`}</Typography>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              size="large"
              fullWidth
              className={classes.form__button}
              disableRipple
              disabled={isSubmitting || !isValid || !dirty}
            >
              {t('form.send')}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ContactsForm;
