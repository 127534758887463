import React from 'react';
import cn from 'classnames';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  pageWrapper: {
    display: 'flex',
    flexGrow: 1,
  },
  alignCenter: {
    alignItems: 'center',
  },
}));

const PageWrapper = ({ children, alignCenter, disableGutters }) => {
  const classes = useStyles();
  return (
    <div
      className={cn(classes.pageWrapper, {
        [classes.alignCenter]: alignCenter,
      })}
    >
      <Container maxWidth={false} disableGutters={disableGutters}>
        {children}
      </Container>
    </div>
  );
};

PageWrapper.defaultProps = {
  alignCenter: false,
  disableGutters: false,
};

export default PageWrapper;
