import equals from 'ramda/src/equals';

import { DEFAULT_LANG, EN, RU, LANGS } from 'main/constants/langs';

export const isDefaultLang = equals(DEFAULT_LANG);
export const isRuLang = equals(RU);
export const isEnLang = equals(EN);

export const getCurrentLang = (lang) => LANGS.find(equals(lang));

export const getlangFromPath = (pathname) => {
  const [firstPart = ''] = pathname.split('/').filter((part) => part);
  return getCurrentLang(firstPart);
};
