import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import without from 'ramda/src/without';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { LANGS } from 'main/constants/langs';
import useLang from 'client/main/utils/useLang';

import RouterLinkWithLang from './RouterLinkWithLang';

const useStyles = makeStyles((theme) => ({
  appBar_colorWhite: {
    color: '#fff',
  },
  toolbar: {
    display: 'flex',
  },
  toolbar__logo: {
    width: 92,
    [theme.breakpoints.up('lg')]: {
      width: 112,
    },
    [theme.breakpoints.up('xl')]: {
      width: 150,
    },
  },
  appBar__nav: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      marginLeft: 'auto',
    },
  },
  appBar__navLink: {
    marginLeft: 36,
  },
  appBar__navLinkText: {
    fontWeight: 500,
  },
  appBar__langList: {
    [theme.breakpoints.only('xs')]: {
      marginLeft: 'auto',
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 48,
    },
  },
  menuButton: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

const Header = ({ isInverseColor, navRoutes, handleToggleDrawer }) => {
  const classes = useStyles();
  const { currentLang, changeLanguage } = useLang();
  const { t } = useTranslation();

  const availableLangs = without(currentLang, LANGS);
  const handleChangeLang = (lang) => () => changeLanguage(lang);

  return (
    <AppBar
      position="absolute"
      color="transparent"
      elevation={0}
      className={cn({
        [classes.appBar_colorWhite]: isInverseColor,
      })}
    >
      <Toolbar className={classes.toolbar}>
        <div className={classes.toolbar__logo}>
          <Link component={RouterLinkWithLang} to="/">
            <img src="/logo_old.svg" />
          </Link>
        </div>
        <nav className={classes.appBar__nav}>
          {navRoutes.map(({ id, link, keyToName }) => (
            <Link
              key={id}
              component={RouterLinkWithLang}
              to={link}
              color="inherit"
              className={classes.appBar__navLink}
            >
              <Typography
                variant="body1"
                color="inherit"
                className={classes.appBar__navLinkText}
              >
                {t(keyToName).toUpperCase()}
              </Typography>
            </Link>
          ))}
        </nav>
        <nav className={classes.appBar__langList}>
          <div className={classes.langList}>
            {availableLangs.map((lang) => (
              <Button
                key={lang}
                color="inherit"
                disableRipple
                onClick={handleChangeLang(lang)}
              >
                <Typography variant="body1">{lang.toUpperCase()}</Typography>
              </Button>
            ))}
          </div>
        </nav>
        <IconButton
          color="inherit"
          disableRipple
          edge="end"
          onClick={handleToggleDrawer}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

Header.defaultProps = {
  isInverseColor: false,
  navRoutes: {},
};

export default Header;
