import React from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ensureReady, After, getSerializedData } from '@jaredpalmer/after';
import { ThemeProvider } from '@material-ui/core/styles';
import { I18nextProvider, useSSR } from 'react-i18next';

import initFirebase from 'utils/initFirebase';

import routes from './routes';
import theme from './theme';
import Layout from './components/Layout';
import i18n from './i18n';
import './index.css';

initFirebase();

const i18nStore = getSerializedData('initialI18nStore', true);
const i18nLang = getSerializedData('initialLanguage', true);

function Main({ children }) {
  useSSR(i18nStore, i18nLang);

  React.useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <I18nextProvider
      i18n={i18n}
      initialI18nStore={i18nStore}
      initialLanguage={i18nLang}
    >
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <Layout>{children}</Layout>
        </ThemeProvider>
      </BrowserRouter>
    </I18nextProvider>
  );
}

ensureReady(routes).then((data) =>
  hydrate(
    <Main>
      <After data={data} routes={routes} transitionBehavior="instant" />
    </Main>,
    document.getElementById('root')
  )
);

if (module.hot) {
  module.hot.accept();
}
