import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import { db } from 'utils/initFirebase';

import ContactsForm from './ContactsForm';
import ContactsInfo from './ContactsInfo';

const dialogContentKeysByState = {
  success: {
    title: 'form.successTitle',
    text: 'form.successText',
    buttonText: 'button.continue',
  },
  error: {
    title: 'form.errorTitle',
    text: 'form.errorText',
    buttonText: 'button.tryAgain',
  },
};

const ContactsContainer = () => {
  const { t } = useTranslation();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [formSendingState, setFormSendingState] = useState('none');

  const handleOpenDialog = () => setIsOpenDialog(true);
  const handleCloseDialog = () => setIsOpenDialog(false);

  const handleSubmitForm = (values, { resetForm }) =>
    db()
      .collection('orders')
      .doc()
      .set(values)
      .then(() => {
        resetForm();
        setFormSendingState('success');
      })
      .catch(() => setFormSendingState('error'))
      .finally(() => {
        handleOpenDialog();
      });

  const renderDialog = () => {
    if (formSendingState === 'none') {
      return null;
    }

    const contentKeys = dialogContentKeysByState[formSendingState];

    return (
      <Dialog open={isOpenDialog} maxWidth="xs" onClose={handleCloseDialog}>
        <DialogTitle>
          <Typography
            variant="h6"
            color={formSendingState === 'error' ? 'error' : 'initial'}
          >
            {t(contentKeys.title)}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{t(contentKeys.text)}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            disableRipple
            color="primary"
            autoFocus
          >
            {t(contentKeys.buttonText)}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} lg={5} xl={4}>
          <ContactsForm handleSubmitForm={handleSubmitForm} />
        </Grid>
        <Grid item xs={false} sm={1} />
        <Grid item xs={12} md={3}>
          <ContactsInfo />
        </Grid>
      </Grid>
      {renderDialog()}
    </>
  );
};

export default ContactsContainer;
