import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import format from 'date-fns/format';
import last from 'ramda/src/last';
import InfiniteScroll from 'react-infinite-scroller';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { db } from 'utils/initFirebase';
import { getDocumentSnapshotById, unwrapCollection } from 'helpers/firebase';
import useLang from 'main/utils/useLang';
import { NEWS_LENGTH_IN_PAGE } from 'main/constants/main';

const MS_IN_SECOND = 1000;

const useStyles = makeStyles((theme) => ({
  news__item: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 24px)',
    paddingBottom: 24,
    [theme.breakpoints.up('lg')]: {
      height: 'calc(100% - 48px)',
      paddingBottom: 48,
    },
  },
  news__itemTitle: {
    margin: '12px 0 4px 0',
  },
  news__itemDate: {
    marginTop: 'auto',
  },
  news__itemImgLink: {},
  news__itemImgWrapper: {
    position: 'relative',
    paddingTop: '56%',
    overflow: 'hidden',
  },
  news__itemImg: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  news__skeletonItemImg: {
    transform: 'scale(1) !important',
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    padding: '36px 0',
  },
}));

const News = ({ data, allNewsLength }) => {
  let lastVisibleDoc;
  const { currentLang } = useLang();
  const [news, setNews] = useState(data);
  const classes = useStyles();

  useEffect(() => {
    setNews(data);
  }, [data]);

  const loadNews = async () => {
    if (!lastVisibleDoc) {
      const lastDocId = last(news).id;
      lastVisibleDoc = await getDocumentSnapshotById('news', lastDocId);
    }

    return db()
      .collection('news')
      .orderBy('publishedTime', 'desc')
      .startAfter(lastVisibleDoc)
      .limit(NEWS_LENGTH_IN_PAGE)
      .get()
      .then((documentSnapshots) => {
        lastVisibleDoc =
          documentSnapshots.docs[documentSnapshots.docs.length - 1];

        setNews([...news, ...unwrapCollection(documentSnapshots)]);
      });
  };

  const loadMoreNews = () => loadNews().catch(() => loadMoreNews());

  const renderSkeletonItem = () => (
    <Grid item xs={12} md={6}>
      <div className={classes.news__item}>
        <Divider light />
        <Box width="65%">
          <Typography variant="h6" className={classes.news__itemTitle}>
            <Skeleton animation="wave" />
          </Typography>
        </Box>
        <Box width="85%">
          <Typography paragraph color="textSecondary">
            <Skeleton animation="wave" />
          </Typography>
        </Box>
        <Box width={80}>
          <Typography
            paragraph
            variant="body2"
            color="textSecondary"
            className={classes.news__itemDate}
          >
            <Skeleton animation="wave" />
          </Typography>
        </Box>

        <div className={classes.news__itemImgWrapper}>
          <Skeleton
            animation="wave"
            className={cn(classes.news__itemImg, classes.news__skeletonItemImg)}
          />
        </div>
      </div>
    </Grid>
  );

  const renderItem = ({ id, contentByLang, publishedTime, url, imageUrl }) => {
    const { title, description } = contentByLang[currentLang];
    const date = new Date(publishedTime.seconds * MS_IN_SECOND);
    const formatedDate = format(date, 'dd.MM.yyyy');

    return (
      <Grid key={id} item xs={12} md={6}>
        <div className={classes.news__item}>
          <Divider />
          <Link
            href={url}
            target="_blank"
            rel="noreferrer"
            color="inherit"
            underline="none"
          >
            <Typography variant="h6" className={classes.news__itemTitle}>
              {title}
            </Typography>
          </Link>
          <Link
            href={url}
            target="_blank"
            rel="noreferrer"
            color="inherit"
            underline="none"
          >
            <Typography paragraph color="textSecondary">
              {description}
            </Typography>
          </Link>
          <Typography
            paragraph
            variant="body2"
            color="textSecondary"
            className={classes.news__itemDate}
          >
            {formatedDate}
          </Typography>
          <Link
            href={url}
            target="_blank"
            rel="noreferrer"
            color="inherit"
            underline="none"
            className={classes.news__itemImgLink}
          >
            <div className={classes.news__itemImgWrapper}>
              <img src={imageUrl} className={classes.news__itemImg} />
            </div>
          </Link>
        </div>
      </Grid>
    );
  };

  const renderProgress = () => (
    <div className={classes.progress}>
      <CircularProgress />
    </div>
  );

  return (
    <section>
      {!news ? (
        <Grid container spacing={4}>
          {renderSkeletonItem()}
          {renderSkeletonItem()}
        </Grid>
      ) : (
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMoreNews}
          hasMore={news.length < allNewsLength}
          loader={renderProgress()}
        >
          <Grid container spacing={4}>
            {news.map(renderItem)}
          </Grid>
        </InfiniteScroll>
      )}
    </section>
  );
};

export default News;
