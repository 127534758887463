import { useHistory } from 'react-router-dom';

import { DEFAULT_LANG } from 'main/constants/langs';
import { getlangFromPath } from 'main/helpers/langs';
import buildRouteWithLang from 'main/helpers/buildRouteWithLang';

export default function useHistoryWithLang() {
  const history = useHistory();
  const {
    location: { pathname },
  } = history;

  const currentLang = getlangFromPath(pathname) || DEFAULT_LANG;

  const push = (route) => history.push(buildRouteWithLang(currentLang, route));
  const replace = (route) =>
    history.replace(buildRouteWithLang(currentLang, route));

  return { push, replace, location: history.location };
}
