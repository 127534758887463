import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { db } from 'utils/initFirebase';
import { getCollectionLength, unwrapCollection } from 'helpers/firebase';
import { NEWS_LENGTH_IN_PAGE } from 'main/constants/main';
import News from 'main/components/News';
import PageHeader from 'main/components/PageHeader';
import PageWrapper from 'main/components/PageWrapper';

const getNews = () =>
  db()
    .collection('news')
    .orderBy('publishedTime', 'desc')
    .limit(NEWS_LENGTH_IN_PAGE)
    .get()
    .then(unwrapCollection);

const NewsPage = ({ news, allNewsLength }) => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('meta.news.title')}</title>
        <meta name="description" content={t('meta.news.description')} />
      </Helmet>
      <PageWrapper>
        <>
          <PageHeader title={t('news')} />
          <News data={news} allNewsLength={allNewsLength} />
        </>
      </PageWrapper>
    </>
  );
};

NewsPage.getInitialProps = async () => {
  const news = await getNews();
  const allNewsLength = await getCollectionLength('news');

  return { news, allNewsLength };
};

export default NewsPage;
